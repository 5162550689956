<template>
    <div class="modal-wrap">
        <h1 class="tit-h1 d-flex">
            {{ param.headerTitle }}
            <div class="ml-auto align-self-center">
                <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
            </div>
        </h1>
        <div class="modal-box">
            <template v-if="p01">
                <div class="box-ct">
                    <v-form ref="modalForm" v-model="valid" lazy-validation>
                        <div class="table-form save-form">
                            <table>
                                <colgroup>
                                    <col width="20%">
                                    <col width="80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">이름</v-badge>
                                        </th>
                                        <td>
                                            <v-text-field class="form-inp table-form-tag" 
                                                outlined hide-details
                                                :rules="name" 
                                                required
                                                v-model="NAME">
                                            </v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">전화번호</v-badge>
                                        </th>
                                        <td>
                                            <v-text-field class="form-inp table-form-tag" 
                                                outlined hide-details
                                                :rules="phoneNumber"
                                                required
                                                label="'-'를 제외하고 입력하세요."
                                                oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                                                v-model="PHONE_NUMBER">
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-form>
                </div>
            </template>
            <div v-if="p01" class="text-right mt-3">
                <v-btn
                    outlined
                    class="btn-point ml-2"
                    @click="saveRowData"
                >저장
                </v-btn>
            </div>
            <template v-if="p02">
                <v-text-field 
                      class="form-inp table-form-tag form-image" 
                      ref="excelFile" 
                      outlined hide-details
                      type="file"  
                      v-model="EXCEL_FILE">
                </v-text-field>
            </template>
            <v-tooltip v-if="p02" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        dark small 
                        class="btn-main-refresh pa-0 ml-2" 
                        min-width="32px" 
                        height="32px" 
                        plain
                        v-bind="attrs"
                        @click="excelUpload"
                        v-on="on">
                    <v-icon small class="svg-main-down"></v-icon>
                    </v-btn>
                </template>
                <span>엑셀파일 업로드</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: 'MENU_CAP0100',
        components: {},

        props : {
            param:{
                type: Object,
            },
            p01        : {
                type   : Boolean,
                default: false,
            },
            p02        : {
                type   : Boolean,
                default: false,
            },
        },

        data() {
            return {
                modalData:{},
                valid:true,
                name: [
                    v => !!v || '이름은 필수입니다.',
                ],
                phoneNumber:
                [
                    v => !!v || '전화번호는 필수입니다.',
                    v => /^[0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
                ],

                //v-model
                NAME:'',
                PHONE_NUMBER:'',
                EXCEL_FILE:'',

                alertMsg:{
                    success    : "정상적으로 처리되었습니다.",
                    succDel    : "정상적으로 삭제되었습니다.",
                    error      : "시스템 장애가 일어났습니다. 관리자에게 문의하세요.",
                    deleteMsg  : "삭제 하시겠습니까?",
                    saveMsg    : "저장 하시겠습니까?",
                    newCmpIdEmpty : "신규 저장시 캠페인 상세내용을 저장해주세요.",
                    chkFile    : "파일을 선택해주세요.",
                },
            }
        },

        mounted () {},

        computed: {},

        methods: {

            saveRowData() {
                this.param.data=[]
                if (!this.$refs.modalForm.validate()) {
                    return;
                }
                this.modalData={
                    CMP_ALLOW_PARTCP_PHONE_NUM_ID:''
                    ,NAME:this.NAME
                    ,PHONE_NUMBER:this.PHONE_NUMBER
                    ,ROW_NUM:this.param.rowNum
                };
                this.param.data.push(this.modalData);
                this.$emit('submit');
            },

            excelUpload() {
                if(this.mixin_isEmpty(this.param.cmpId)){
                    this.common_alert(this.alertMsg.newCmpIdEmpty, "chk");
                    return false;
                }

                if(this.mixin_isEmpty(this.EXCEL_FILE)){
                    this.common_alert(this.alertMsg.chkFile, "chk");
                    return false;
                }

                this.common_confirm(this.alertMsg.saveMsg, this.uploadExcel, null, null, null, 'chk');
            },

            callNo() {
                this.$store.commit("alertStore/openAlertDialog", {
                    alertDialogToggle: false,
                });
            },

            async uploadExcel() {
                this.callNo();
                await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);

                this.token = this.mixin_getToken();

                let requestData = {
                    headers: {
                        SERVICE:'files',
                        METHOD:'upload',
                        TYPE:'BIZ_SERVICE',
                        MENU_TYPE:'CAM',
                        URL:process.env.VUE_APP_API_URL+"api/files/excel/upload",
                        AUTHORIZATION:this.token,
                        CMP_ID:this.param.cmpId,
                        QUTN_ID:this.param.qutnId,
                        EXCEL_HEADER:['NAME','PHONE_NUMBER'],
                        PROC_ID:this.user_id,
                        'Content-Type':'multipart/form-data',
                    },
                    sendData:{}
                };

                let vue=this;
                let localSaveKeysObj=[];
                let input=vue.$refs.excelFile.$refs.input;
                let sknObj={};
                const formData = new FormData();
                for (let index = 0; index < input.files.length; index++) {
                    const file = input.files[index];
                    if(input.type === 'file' && file !== undefined){
                        if(file.name.indexOf('.xls')>0||file.name.indexOf('.xlsx')>0){
                            if(file.name.includes('참여 허용된 전화번호')) {
                                formData.append('fileList', file);
                                sknObj={};
                                sknObj['key']=input.name;
                                sknObj['fileName']=file.name;
                                localSaveKeysObj.push(sknObj); //업로드할 파일 적재
                            }else{
                                this.common_alert('템플릿명:"참여 허용된 전화번호"\n명칭이 들어간 템플릿 샘플 양식(다운로드)으로 작업 후 업로드 해주세요.', 'chk');
                            }
                        }else{
                            this.common_alert('엑셀 파일이 아닙니다.', 'error');
                        }
                    }
                }
                this.saveKeysObj=localSaveKeysObj;

                if(this.saveKeysObj.length>0){
                    axios.post(requestData.headers.URL, formData, {
                    headers: requestData.headers
                    }).then(response => {
                        this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
                        const returnDatas=response.data;
                        if(returnDatas.responseMessage==='정상'){ //파일 업로드 정상 후 처리
                            const retDatas=returnDatas.data.DATA;
                            this.uploadCallBack(retDatas);
                        }else{
                            return alert(this.alertMsg.error);
                        }
                    }).catch(error => {
                        this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
                        return error.message;
                    });
                }
            },

            uploadCallBack(retDatas) {
                this.param.data=[];
                for (let index = 1; index <= retDatas.length; index++) {
                    const element = retDatas[index-1];
                    
                    this.modalData={
                        CMP_ID:this.param.cmpId,
                        CMP_ALLOW_PARTCP_PHONE_NUM_ID:element.CMP_ALLOW_PARTCP_PHONE_NUM_ID,
                        NAME:element.NAME,
                        PHONE_NUMBER:element.PHONE_NUMBER,
                        ROW_NUM:this.param.rowNum+index
                    };
                    this.param.data.push(this.modalData);
                }
                this.callNo();
                this.$emit('submit');
            },
        },
    }
</script>